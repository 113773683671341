import React, { useState } from 'react';
import { Grid, Paper, TextField, Button, Typography, makeStyles, Box } from '@material-ui/core'
import axios from '../../util/axios';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5%',
            height: '55vh',
            width: '40vw',
            margin: "20px auto",
            boxShadow: " 0 0 0 4px rgba(63,63,68,0.05), 0 1px 3px 2px rgba(63,63,68,0.15)",
            [theme.breakpoints.between('sm', 'md')]: {
                height: '55vh',
                width: '45vw',
            },
            [theme.breakpoints.down('sm')]: {
                // height: '55vh',
                width: '55vw',
            },
            ['@media(max-width: 600px)']: {
                // height: '55vh',
                width: '80vw',
            },
            ['@media(min-height: 1000px)']: {
                // height: '50vh',
            }
        },
        btn: {
            margin: '20px 0 '
        },
        field: {
            marginBottom: 20,

        },
        bigTitle: {
            fontWeight: 'bold',
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: theme.typography.h4.fontSize,

            },
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.h5.fontSize,
            },
        },
        smallTitle: {
            fontSize: '16px',
        },
        emailSent: {
            color: 'green',
        },
        error: {
            color: 'red'
        },
        footerTitle: {
            color: 'white',
            backgroundColor: '#FF2C46',
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: theme.typography.h5.fontSize,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1em',
            },
            ['@media(max-width: 600px)']: {
                fontSize: '0.8em',
            },
            ['@media(max-width: 300px)']: {
                fontSize: '0.7em',
            }
        },
        shapeBox: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -3,
            left: 0,
            right: 0
        },
        rect: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -2,
            left: 0,
            right: 0,
            clipPath: "inset(50% 0 0 0)",
            height: '100%',
            backgroundColor: '#FF2C46',
        },
        forgotPass: {
            color: '#8cb6ff',
            fontWeight: 400,
            fontSize: 14
        },
        ellipse: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -1,
            left: 0,
            right: 0,
            clipPath: "ellipse(58% 12% at 50% 56%)",
            height: '100%',
            backgroundColor: 'white',
        }
    }
})

function ForgotPasswordForm() {
    const classes = useStyles();
    const { t } = useTranslation()
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState('');
    const [isSent, setIsSent] = useState(false);

    const submitForm = async(e) => {
        setIsSent(false);
        e.preventDefault()
        setEmailError('')
        if (!email) {
            setEmailError(t("please-enter-your-email"));
            return;
        } else {
            if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).exec(email)) {
                setEmailError(t("Invalid-Email"))
                return;
            }
        }

        let body = { email };
        axios.post('admin/v1/password/create', body).then(() => {
            setIsSent(true);
        }).catch(error => {
            console.log('Sign up error', error);
        })
    };

    return (
        <Grid >
            <Paper className={classes.paper}>
                <Grid align="center" style={{ marginBottom: "15px" }}>
                    <Typography variant="h3" className={classes.bigTitle} gutterBottom>{t("SMILE TOGETHER")}</Typography>
                    <Typography variant="p" className={classes.smallTitle}>{t("Forgot-password-content")}</Typography>
                </Grid>
                <form onSubmit={(e) => { submitForm(e) }}>
                    <TextField label='Email' placeholder='Enter your email address' variant="standard" fullWidth className={classes.field} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    <Typography align='center' variant='body1' className={classes.error} >{emailError}</Typography>
                    <Button type='submit' color='secondary' variant="contained" fullWidth className={classes.btn} >{t('submit')}</Button>
                    {isSent && <Typography variant="p" className={classes.emailSent}>{t("rest-password-email-sent", { email })}</Typography>}
                </form>
            </Paper>
            <Paper elevation={0} style={{ backgroundColor: 'transparent' }}>
                <Typography align='center' variant='h5' className={classes.footerTitle} >{t('LET’S-MAKE-THE-WORLD-SMILE-MORE-TOGETHER.')}</Typography>
            </Paper>
            <Box className={classes.shapeBox} >
                <div className={classes.rect}></div>
                <div className={classes.ellipse}></div>
            </Box>
        </Grid>
    );
}

export default ForgotPasswordForm;
