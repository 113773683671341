import React, { createRef, useState } from 'react';
import { Grid, Paper, TextField, Button, Typography, makeStyles, Box } from '@material-ui/core'
import Cookies from 'js-cookie';
import axios from '../../util/axios';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import ReCAPTCHA from "react-google-recaptcha";
import parse from "react-html-parser";

const useStyles = makeStyles((theme) => {
    return {
        paper: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            padding: '0 5%',
            height: '55vh',
            width: '40vw',
            margin: "20px auto",
            boxShadow: " 0 0 0 4px rgba(63,63,68,0.05), 0 1px 3px 2px rgba(63,63,68,0.15)",
            [theme.breakpoints.between('sm', 'md')]: {
                height: '55vh',
                width: '45vw',

            },
            [theme.breakpoints.down('sm')]: {

                // height: '55vh',
                width: '55vw',
            },
            ['@media(max-width: 600px)']: {
                // height: '55vh',
                width: '80vw',
            },
            ['@media(min-height: 1000px)']: {
                // height: '50vh',
            }
        },
        btn: {
            margin: '20px 0 '
        },
        field: {
            marginBottom: 20,

        },
        bigTitle: {
            fontWeight: 'bold',

            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: theme.typography.h4.fontSize,

            },
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.h5.fontSize,
            },
        },
        smallTitle: {
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: theme.typography.h5.fontSize,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.h6.fontSize,
            },
        },
        error: {
            color: 'red'

        },
        footerTitle: {
            color: 'white',
            backgroundColor: '#FF2C46',
            [theme.breakpoints.between('sm', 'md')]: {
                fontSize: theme.typography.h5.fontSize,
            },
            [theme.breakpoints.down('sm')]: {
                fontSize: '1em',
            },
            ['@media(max-width: 600px)']: {
                fontSize: '0.8em',
            },
            ['@media(max-width: 300px)']: {
                fontSize: '0.7em',
            }
        },
        shapeBox: {
            // height:'40vh',
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -3,
            left: 0,
            right: 0
        },
        rect: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -2,
            left: 0,
            right: 0,
            clipPath: "inset(50% 0 0 0)",
            height: '100%',
            backgroundColor: '#FF2C46',
        },
        forgotPass: {
            color: '#8cb6ff',
            fontWeight: 400,
            fontSize: 14
        },
        ellipse: {
            position: 'absolute',
            top: 0,
            bottom: 0,
            zIndex: -1,
            left: 0,
            right: 0,

            clipPath: "ellipse(58% 12% at 50% 56%)",
            height: '100%',
            backgroundColor: 'white',

        }
    }
})

function SignUpForm() {
    const recaptchaRef = createRef();
    const isLocalEnvironment = process.env.NODE_ENV === 'development';
    const query = new URLSearchParams(document.location.search);
    const classes = useStyles();
    const { t } = useTranslation()
    const [email, setEmail] = useState(query.get('email'));
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [errors, setErrors] = useState('');
    let navigate = useNavigate();

    const submitForm = async(e) => {
        e.preventDefault()
        setEmailError('')
        setPasswordError('')
        setErrors('')
        if (!password) {
            setPasswordError(t("Please-Enter-your-password"))
        }
        if (!email) {
            setEmailError(t("please-enter-your-email"))
        }
        else {
            if (!(/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i).exec(email)) {
                setEmailError(t("Invalid-Email"))
                return;
            }
        }

        if (!email || !password) {
            return;
        }
        let body = { email, password };
        if (!isLocalEnvironment) {
            const token = await recaptchaRef.current.executeAsync();
            body = {
                ...body,
                'g-recaptcha-response': token,
            };
        }
        axios.post('admin/v1/auth/sign-up/doctor', body).then(response => {
            if(response.data.success) {
                axios.post('admin/v1/auth/login/doctor', body).then(response => {
                    Cookies.set('access_token_clinic', response.data.access_token, { expires: 7 })
                    const name = response.data.name
                    Cookies.set('clinic_name', name, { expires: 7 })
                    Cookies.set('clinic_id', response.data.clinic_id, { expires: 7 })
                    Cookies.set('is_scan_at_home', response.data.is_scan_at_home, { expires: 7 })
                    if(Cookies.get('clinic_impersonate')){
                        Cookies.remove('clinic_impersonate');
                    }
                    navigate('/dashboard/patient/add')
                }).catch(error => {
                    console.log('Log in error', error);
                    setPasswordError( t("Please-check-your-email-and-password-and-try-again"))
                })
            } else {
                setErrors(parse(t('sign-up-error')));
            }
        }).catch(error => {
            console.log('Sign up error', error);
        })
    };

    return (
        <Grid >
            {
                !isLocalEnvironment && (
                    <ReCAPTCHA
                        ref={recaptchaRef}
                        sitekey={process.env.REACT_APP_RECAPTCHA_TOKEN_SITE_KEY}
                        size="invisible"
                    />
                )
            }
            <Paper className={classes.paper}>
                <Grid align="center" style={{ marginBottom: "15px" }}>
                    <Typography variant="h3" className={classes.bigTitle} gutterBottom>
                        {t("SMILE TOGETHER")}
                    </Typography>
                    <Typography variant="p" className={classes.smallTitle}>
                        {t("The-clinic-partner-portal")}
                    </Typography>
                </Grid>
                <form onSubmit={(e) => { submitForm(e) }}>
                    <TextField label='Email' placeholder='Enter your email address' variant="standard" fullWidth className={classes.field} value={email} onChange={(e) => { setEmail(e.target.value) }} />
                    <Typography align='center' variant='body1' className={classes.error} >{emailError}</Typography>
                    <TextField
                        label="Set Password"
                        placeholder="Enter password"
                        variant="standard"
                        type="password"
                        fullWidth
                        className={classes.field}
                        value={password}
                        onChange={(e) => {
                            setPassword(e.target.value);
                        }}
                    />
                    <Typography align='center' variant='body1' className={classes.error}>{passwordError} </Typography>
                    <Typography align='center' variant='body1' className={classes.error}>{errors} </Typography>
                    <Button type='submit' color='secondary' variant="contained" fullWidth className={classes.btn} >{t('sign-up')}</Button>
                </form>
            </Paper>
            <Paper elevation={0} style={{ backgroundColor: 'transparent' }}>
                <Typography align='center' variant='h5' className={classes.footerTitle} >{t('LET’S-MAKE-THE-WORLD-SMILE-MORE-TOGETHER.')}</Typography>
            </Paper>
            <Box className={classes.shapeBox} >
                <div className={classes.rect}></div>
                <div className={classes.ellipse}></div>
            </Box>
        </Grid>);
}

export default SignUpForm;
