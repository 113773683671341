import React from 'react';
import Calendar from '../../Components/Calendar/Calendar'
import Patients from '../../Components/Patients/Patients'
import TabNav, { TabPanel } from '../../Components/TabNav/Tabnav'
import { makeStyles } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import theme from '../../lib/theme'
import Layout from '../../Components/TopNav/Topnav'
import Ipr from '../../Components/Ipr/IprPage'
import CancelledBooking from '../../Components/CancelledBooking/CancelledBooking'
// import NeedRescheduling from "../../Components/NeedRescheduling/need-rescheduling.component";
const useStyles = makeStyles(() => ({
    boxWrap: {
        padding: 25,
        backgroundColor: theme.palette.background.body,
    },
}));

export default function CalendarPage(){
    const { t } = useTranslation()
    const classes = useStyles()
    const tabNavProps = {
        tabs: [
            {
                id: 0,
                label: t('Patients'),
            },
            {
                id: 1,
                label: t('Calendar'),
            },
            {
                id: 2,
                label: t('Treatment-Procedures'),
            },
            {
                id: 3,
                label: t('Walk-in'),
            },
            {
                id: 4,
                label: t('Cancelled-Booking'),
            }
        ],
    };

    return (
        <Layout>
            <div className={classes.boxWrap}>
                <TabNav {...tabNavProps}>
                    <TabPanel>
                        <Patients />
                    </TabPanel>
                    <TabPanel>
                        <Calendar />
                    </TabPanel>
                    <TabPanel>
                        <Ipr walkIn={false} />
                    </TabPanel>
                    <TabPanel>
                        <Ipr walkIn={true} />
                    </TabPanel>
                    {/* <TabPanel>
                        <NeedRescheduling />
                    </TabPanel> */}
                    <TabPanel>
                        <CancelledBooking />
                    </TabPanel>
                </TabNav>
            </div>
       </Layout>
    )
}